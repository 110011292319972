import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap, catchError, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  smartyStreetsKey = '5919463735736937';

  _validate: string;
  _lastName: string;
  _firstName: string;
  _email: string;
  _emailConfirm: string;
  _submitted: string;
  _notified: string;

  set notified(value: string) {
    this._notified = value;
  }

  get notified(): string {
    return this._notified;
  }
  
  
  set submitted(value: string) {
    this._submitted = value;
  }

  get submitted(): string {
    return this._submitted;
  }

  set validate(value: string) {
    this._validate = value;
  }

  get validate(): string {
    return this._validate;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set email(value: string) {
    this._email = value;
  }

  get email(): string {
    return this._email;
  }

  set emailConfirm(value: string) {
    this._emailConfirm = value;
  }

  get emailConfirm(): string {
    return this._emailConfirm;
  }


  constructor(
    private http: HttpClient
  ) { }

  getMetaData(): Observable<any> {
    return this.http.get('assets/data/metadata.json');
  }
  getAppConfig():Observable<any>{
    return this.http.get('assets/config/app-config.json');
  }
  saveSubmitEnrollment(operation: string, registerKey: string, payload: any): Observable<any> {
    const url: string = `${environment.providerAPIEndpointExternal}/enroll/${operation}/${registerKey}`;
    return this.http.post<any>(url, payload)
      .pipe(
        map(data => (
          {
            result: data,
            error: null
          }
        ),

          catchError(err => {
            return of({
              result: null,
              error: err.message
            });
          })
        )
      );
  }

  verifyAttachment(registerKey: string, file: File, filename: string, documentType: string, expiration: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, filename);
    formData.append('attachment_Type', documentType);

    const operation = 'verifyAttachment';
    const host: string = `${environment.providerAPIEndpointExternal}/enroll`;
    const url: string = `${host}/${operation}/${registerKey}`;

    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });

  }

  uploadAttachment(registerKey: string, file: File, filename: string, documentType: string, expiration: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, filename);
    formData.append('attachment_Type', documentType);
    formData.append('expiration_Date', expiration);

    const operation = 'uploadAttachment';

    const host: string = `${environment.providerAPIEndpointExternal}/enroll`;
    const url: string = `${host}/${operation}/${registerKey}`;

    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });

  }

  deleteAttachment(attachmentId: string): Observable<any> {

    const operation = 'attachment';
    const host: string = `${environment.providerAPIEndpointExternal}/enroll`;
    const url: string = `${host}/${operation}/${attachmentId}`;

    return this.http.delete<any>(url);
  }

  getEnroll(registerKey: string): Observable<any> {
    const url = `${environment.providerAPIEndpointExternal}/enroll/${registerKey}`;
    return this.http.post<any>(url,'')
      .pipe(
        map(data => (
          {
            result: data,
            error: null
          }
        ),

          catchError(err => {
            return of({
              result: null,
              error: err.message
            });
          })
        )
      );
  }

  getInternalView(payloadId: string): Observable<any> {
    const url = `${environment.providerAPIEndpointInternal}/enroll/${payloadId}`;
    return this.http.get<any>(url)
      .pipe(
        map(data => (
          {
            result: data,
            error: null
          }
        ),

          catchError(err => {
            return of({
              result: null,
              error: err.message
            });
          })
        )
      );
  }

  getDownloadUrl(attachmentId: string): string {
    return `${environment.providerAPIEndpointExternal}/enroll/attachment/${attachmentId}`;
  }

  getDownloadReviewDocumentUrl(registerKey: string): string {
    return `${environment.providerAPIEndpointExternal}/enroll/download/${registerKey}`;
  }

  getOperational(registerKey: string): Observable<any> {
    const url: string = `${environment.providerAPIEndpointExternal}/enroll/${registerKey}`;
    return this.http.get<any>(url)
      .pipe(
        map(data => (
          {
            result: data,
            error: null
          }
        ),

          catchError(err => {
            return of({
              result: null,
              error: err.message
            });
          })
        )
      );
  }

  getAppStatus(registerKey: string) {
    const url: string = `${environment.providerAPIEndpointExternal}/enroll/statusTracker/${registerKey}`;
    //const url: string = `https://my.api.mockaroo.com/test_appstatus_v01.json?key=235133b0`; //credentialing
    //const url: string = `http://www.mocky.io/v2/5e5fc46c310000a9f8afdf48`; //credentialing w 5 steps
    //const url: string = `http://www.mocky.io/v2/5e61100133000065cf97bf4f`; //denied with isDenied
    //const url: string = `http://www.mocky.io/v2/5e6116c4330000bf1897bf90`; //denied w/ partial dates and isDenied only on Denied status



    return this.http.get<any>(url)
      .pipe(
        map(data => (
          {
            result: data,
            error: null
          }
        ),

          catchError(err => {
            return of({
              result: null,
              error: err.message
            });
          })
            
        )
      );
  }

  getHealthCheck(): Observable<boolean> {
    return this.http.get<any>(`${environment.providerAPIEndpointExternal}/healthcheck`).pipe(
      timeout(5000),
      map(response => true),

      catchError(err => {
        return of(false);
      })
    )
  }

  getAddressMatch(search: string): Observable<any> {   

      let url = `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${this.smartyStreetsKey}`;
      // url = `api/address-match?key=${this.smartyStreetsKey}`;

      url += `&search=${encodeURIComponent(search)}`;
      return this.http.get<any>(url);


  }

  verifyAddress(street: string, street2: string, city: string, state: string, zipcode: string): any {
    let url = `https://us-street.api.smartystreets.com/street-address?key=${this.smartyStreetsKey}`;
    // url = `api/street-address?key=${this.smartyStreetsKey}`;

    url += `&street=${encodeURIComponent(street)}`;
    url += `&street2=${encodeURIComponent(street2)}`;
    url += `&city=${encodeURIComponent(city)}`;
    url += `&state=${encodeURIComponent(state)}`;
    url += `zipcode=${encodeURIComponent(zipcode)}`;
    return this.http.get<any>(url);
  }

  cancelApplication(registerKey: string): Observable<any> {
    const url = `${environment.providerAPIEndpointExternal}/user/sendCancelation/${registerKey}`;
    return this.http.post<any>(url,'');
  }

  continueApplication(registerKey: string): Observable<any> {
    const url = `${environment.providerAPIEndpointExternal}/user/sendNotification/${registerKey}`;
    return this.http.post<any>(url,'');
  }

  getApplicationStatus(registerKey: string): Observable<any> {
    const url = `${environment.providerAPIEndpointExternal}/user/validate/${registerKey}`;
    return this.http.post<any>(url, '').pipe(
      map(response => response),

      catchError(err => {
        return of({})
      })
    )
  }

  getApplicationFound(registerKey: string): Observable<boolean> {
    const url = `${environment.providerAPIEndpointExternal}/user/validate/${registerKey}`;
    return this.http.post<any>(url, '').pipe(

      map(response => {
        return true
      }),

      catchError(err => {
        return of(false);
      })
    )
  }

    groupExists(groupNPI: string,groupTaxID: string,beaconGroupID: string): Observable<any> {
  //  const url: string = `${environment.providerAPIEndpointExternal}/enroll/GroupExists?groupNPI=${groupNPI}&groupTaxId=${groupTaxID}&groupProviderNumber=${beaconGroupID}`;
  const url: string = `${environment.providerAPIEndpointExternal}/enroll/GroupExists/${groupNPI}/${groupTaxID}/${beaconGroupID}`;
       return this.http.get<any>(url).pipe(
      map(response => response),

      catchError(err => {
        return of({})
      })
    )
  }

/*
  groupExists(groupNPI: string,groupTaxID: string,beaconGroupID: string)
   {
    const url: string = `${environment.providerAPIEndpointExternal}/enroll/GroupExists?groupNPI=${groupNPI}&groupTaxID=${groupTaxID}&groupProviderNumber=${beaconGroupID}`;
    return this.http.post<boolean>(url, '').pipe(
      map(response => response),

      catchError(err => {
        return of({})
      })
    ).toPromise();
  }
*/
    
  checkUserStatus(registerKey: string): Observable<any> {
    const url = `${environment.providerAPIEndpointExternal}/user/userStatus/${registerKey}`;
    return this.http.get<any>(url);
  }

}
