import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'npe';
  dateToday: number = Date.now();
}

window.onscroll = function() {scrollFunction()};
document.addEventListener("DOMContentLoaded", function(event) { 
  document.body.style.paddingTop = (document.getElementById("npe-header").offsetHeight+20) + "px";
});

function scrollFunction() {
   if ((document.body.scrollTop > 100) || (document.documentElement.scrollTop > 100)) {
    document.getElementById("npe-header").style.minHeight = "62px";
    document.body.style.paddingTop = "82px";
    document.getElementById("npe-logo").style.maxWidth = "120px";
  } else {
    document.getElementById("npe-header").style.minHeight = "83px";
    document.body.style.paddingTop = "103px";
    document.getElementById("npe-logo").style.maxWidth = "200px";
  } 
}