import { Component, OnInit } from '@angular/core';

import { DataService } from '../services/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-healthpage',
  templateUrl: './healthpage.component.html',
  styleUrls: ['./healthpage.component.scss']
})
export class HealthPageComponent implements OnInit {

  healthy: boolean;
  timestamp: any;

  constructor(
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.dataService.getHealthCheck().subscribe(healthy => {
      this.healthy = healthy;
      this.timestamp = Date.now();
    });
  }
}