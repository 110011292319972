
import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UserService } from '../user/user.service';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators, ReactiveFormsModule, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { RegisterUser, RegisterUserResponse } from '../user/user';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastService } from '../services/toast/toast.service';
import { environment } from 'src/environments/environment';
import { DataService } from '../services/data.service';


@Component({
  selector: 'app-root',
  templateUrl: './validateuser.component.html',
  styleUrls: ['./validateuser.component.scss']
})

export class ValidateuserComponent implements OnInit {
  title = 'Validate User';
  @Input() actionType;
  @Input() RegisterUserResponse: RegisterUserResponse[];

  UserForm: FormGroup;
  submitted = false;
  error: boolean = false;
  message = '';
  buttonText = 'Validate User';
  success: boolean = false;
  inActive: boolean = false;
  formActionType: string;
  register_Key: any;
  registerUser = new RegisterUser;
  registerUserResponse = new RegisterUserResponse;
  flagType = "validate";
  applicationsubmitted = false;
  sentnewlink = true;
  loading = false;

  constructor(
    private UserService: UserService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private dataService: DataService,

  ) { }

  onSubmit() {
    this.submitted = true;
    if (this.register_Key === null)
      return;
    this.submitUser(this.register_Key);
  }

  ngOnInit() {
    this.register_Key = this.route.snapshot.paramMap.get('register_Key');
    if (this.register_Key === null)
      return;
    this.loading = true;
    this.ValidateUser(this.register_Key);
    this.loading = false;

  }

  


  submitUser(register_Key: string) {
    this.loading = true;
    if (this.buttonText === 'Continue') {
      this.dataService.validate = 'validated';
      this.router.navigate(['/join'], { queryParams: { registerKey: register_Key } });
    }
    else if (this.buttonText === 'Validate User') 
    {
      this.ValidateUser(register_Key);
      this.loading = false;
    }
    else {
      this.sentNewLink();
      this.loading = false;

    }

    //this.router.navigate( ['/join/',register_Key]);
  }

  ValidateUser(register_Key: string) {

    this.UserService.validateUser(register_Key).subscribe(
      response => {

        if (response) {
          this.flagType = "validate";
          this.applicationsubmitted = false;
          this.register_Key = response['register_Key'];
          this.dataService.email = response.email;
          this.dataService.lastName = response.last_Name;
          this.dataService.firstName = response.first_Name;
          this.dataService.emailConfirm = response.email;
          if(response['status'] === 'I')
          {
               this.inActive=true;
          }
          else
          {
          if (response['user_Verified'] === 'Y' && response['app_Submitted'] !== 'Y' && response['resume_App'] !== 'Y') {
            if (response['continue_App'] === 'Y') 
            {
              this.dataService.validate = 'validated';
              this.router.navigate(['/join'], { queryParams: { registerKey: register_Key } });
            }
            else 
            {
              this.applicationsubmitted = true;
              this.buttonText = 'Continue'
              this.message = 'Your email address has been verified. Please click on the button below to access the provider enrollment form generated for you.';
              this.toastService.show({
                text: 'User successfully validated.',
                type: 'success'
              });
            }
          }
          else if (response['app_Submitted'] === 'Y') 
          {
            if (response['reactivate'] === 'N') 
            {
              this.applicationsubmitted = false;
              this.router.navigateByUrl("/submitted/"+register_Key);
              //this.message = `${register_Key} This application has already been submitted.   Please allow at least 3 weeks from  the  time  of  the  submission  to  check  the  status  of  the  application  or  ask  questions  on  enrollment process by calling our National Provider Service Line at ${environment.providerPhoneNumber}`;
            }
            else {
              this.applicationsubmitted = true;
              this.buttonText = 'Send New Link';
              this.flagType = 'sendApplicationLink';
              this.message = 'The link from your email has expired. To continue, you can request a new link.';

              this.toastService.show({
                text: 'User successfully validated.',
                type: 'success'
              });
            }

            
          }
          else if (response['reactivate'] === 'Y') {
            this.applicationsubmitted = true;
            this.buttonText = 'Send New Link';
            this.flagType = 'reactivate';
            this.message = 'The link from your email has expired. To continue, you can request a new link.';

            this.toastService.show({
              text: 'User successfully validated.',
              type: 'success'
            });
          }
          else if (response['resend_Activation'] === 'Y') {
            this.applicationsubmitted = true;
            this.buttonText = 'Send New Link';
            this.flagType = 'resendActivation';
            this.message = 'The link from your email has expired. To continue, you can request a new link.';
            this.toastService.show({
              text: 'User successfully validated.',
              type: 'success'
            });
          }
          else if (response['resume_App'] === 'Y') {
            if (response['continue_App'] === 'Y') {
              this.dataService.validate = 'validated';
              this.router.navigate(['/join'], { queryParams: { registerKey: register_Key } });
            }
            else {
              this.applicationsubmitted = true;
              this.buttonText = 'Send New Link';
              this.flagType = 'sendApplicationLink';
              this.message = 'The link from your email has expired. To continue, you can request a new link.';

              this.toastService.show({
                text: 'User successfully validated.',
                type: 'success'
              });
            }
          }
          else if (response['start_App'] === 'Y') {
            this.applicationsubmitted = true;
            this.buttonText = 'Send New Link';
            this.flagType = 'sendApplicationLink';
            this.message = 'Your email address has been verified. To continue, you can request a new link.';

            this.toastService.show({
              text: 'User successfully validated.',
              type: 'success'
            });
          }

          this.registerUser.email = response.email;
          this.registerUser.lastName = response.last_Name;
          this.registerUser.firstName = response.first_Name;
          this.registerUser.register_Key = response.register_Key;
        }
                 
        }
        else {
          this.message = `Error occured while validating user`;
          this.success = false;
          this.error = true;
          this.applicationsubmitted = false;
          console.log(`Error occured from service while validate user`);
          this.toastService.show({
            text: 'Error occured while validate user',
            type: 'warning'
          });

        }
      },
      error => {
        this.message = `Error occurred validate user.`;
        this.success = false;
        this.error = true;
        this.applicationsubmitted = false;
        console.log(`Error occured from service while validate user`);
        this.toastService.show({
          text: 'Error occurred validate user.',
          type: 'warning'
        });
      }
    );
  }

  sentNewLink() {
    this.UserService.sentNewLink(this.register_Key, this.flagType).subscribe(

      response => {
        if (response) {
          this.applicationsubmitted = false;
          this.message = "An email with a link to access your enrollment form has been sent to your email address. Please check your email."
          console.log(`Sent new lik successfully.`);

          this.toastService.show({
            text: 'New link sent successfully.',
            type: 'success'
          });

        }
        else {
          this.success = false;
          this.error = true;
          console.log(`Error occured sent new link.`);

          this.toastService.show({
            text: 'Error occured sent new link.',
            type: 'warning'
          });
        }

      },
      error => {
        this.message = `Error occured sent email to user: ${error.error}`;
        this.success = false;
        this.error = true;
        this.applicationsubmitted = false;
        console.log(`Error occured while sent email to user`);
        this.toastService.show({
          text: 'Error occured sent email to user',
          type: 'warning'
        });
      }
    );
  }

}



