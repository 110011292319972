import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class NotFoundGuard implements CanActivate {

  constructor(
    private router: Router,
    private dataService: DataService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    let registerKey: string = route.queryParams.registerKey;
    if (registerKey === undefined) {
      registerKey = route.params.register_Key;
    }
    console.log('can-activate', registerKey, route);
    return this.dataService.getApplicationFound(registerKey).pipe(
      tap(result => console.log('notfound.status', result)),
      map(result => (result) ? (result) : this.router.parseUrl(`/invalid/notfound`))
    )
  }

}

