import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from '../services/data.service';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  npsPhone = '';
  submitted = true;

  constructor(private dataService: DataService,
    private locationStrategy: LocationStrategy
    ) { }

  ngOnInit() {

    if( this.dataService.submitted === 'Submitted')
    {
      this.submitted =true;
    }
    else
    {
      this.submitted =false;
      this.preventBackButton();
      
    }

    //set npsphone from environment variables
    this.npsPhone = `${environment.providerPhoneNumber}`;

  }

  // Define a function to handle back button and use anywhere
  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }

}
