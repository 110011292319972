import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

enum ViewState {
  Canceled,
  Notfound,
  Notdefined
}

@Component({
  selector: 'app-invalid',
  templateUrl: './invalid.component.html',
  styleUrls: ['./invalid.component.scss']
})
export class InvalidComponent implements OnInit {
  ViewState = ViewState;

  viewState = ViewState.Canceled;

  statusMap: any = {
    canceled: ViewState.Canceled,
    notfound: ViewState.Notfound
  }

  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.viewState = this.statusMap[this.route.snapshot.params.status];
    if (this.viewState === undefined) {
      this.viewState = ViewState.Notdefined;
    }
  }

}