import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from  'rxjs/operators';

import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class HealthCheckGuard implements CanActivate {

  constructor(
    private router: Router,
    private dataService: DataService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.dataService.getHealthCheck().pipe(
      map(result => (result) ? (result) : this.router.parseUrl('/down'))
    );
  }

}