import { Component, OnInit, Input, ElementRef, ViewChild ,VERSION} from '@angular/core';
import { DatePipe } from '@angular/common';
import { UserService } from './user.service';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators, ReactiveFormsModule, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { RegisterUser, RegisterUserResponse } from './user';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../services/toast/toast.service';
import { environment } from 'src/environments/environment';
import { asLiteral } from '@angular/compiler/src/render3/view/util';


@Component({
  selector: 'app-root',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  title = 'User Registration';
  @Input() actionType;
  @Input() RegisterUser: RegisterUser[];
  @Input() RegisterUserResponse: RegisterUserResponse[];

  UserForm: FormGroup;
  submitted = false;
  error: boolean = false;
  flagType: any;
  message = '';
  registered = false;
  applicationsubmitted = true;
  buttonText = 'Register';
  success: boolean = false;
  formActionType: string;
  register_Key: any;
  isDraft = false;
  headerName: string;
  registerUser = new RegisterUser;
  registerUserResponse = new RegisterUserResponse;
  loading = false;
  public version = VERSION.full;
  
  
  constructor(
    private UserService: UserService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private toastService: ToastService,

  ) { }

  get f() {
    return this.UserForm.controls;
  }
  onSubmit() {

    this.submitted = true;
    if (!this.UserForm.valid)
      return;
    this.saveUser();
  }

  ngOnInit() {
    this.initializeUserForm();
   // this.addRecaptchaScript();
    this.formActionType = this.actionType;

    if (this.formActionType === "Edit") {
      this.headerName = "Edit User";
    }
    else {
      this.headerName = "Register User";
    }
  }

  initializeUserForm() {
    this.UserForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      confirmEmail: ['', [Validators.required, Validators.email]],
      recaptchaReactive: ['',Validators.required],
    }
      ,
      {
        validator: this.MustMatch('email', 'confirmEmail')
      }
    );
  }

  saveUser() {
    this.loading = true;
    const formRawValue = this.UserForm.getRawValue();
    if (formRawValue != null) {
      this.registerUser.firstName = formRawValue.firstName;
      this.registerUser.lastName = formRawValue.lastName;
      this.registerUser.email = formRawValue.email;
      this.registerUser.confirmEmail = formRawValue.confirmEmail;

    }
    this.UserService.saveUser(this.registerUser).subscribe(

      response => {
        if (response) {
          this.flagType = "sendApplicationLink";
          this.register_Key = response.register_Key;

          if (response.email_Sent === 'Y') {
            this.applicationsubmitted = false;
            this.buttonText = 'Email Sent'
            this.success = true;
            this.error = false;
            this.message = 'Thank you for your registration request with ' + this.registerUser.lastName + ',  '+ this.registerUser.firstName +' .  An email has been sent to ' + this.registerUser.email + ' with a link to verify your email address and to begin your request to join our network. This link will remain active for 24 hour, in the event that you are disconnected or unable to complete. Please check your email for secure link.';
          }
          else if (response.app_Submitted === 'Y') {
            this.applicationsubmitted = false;
            const providerPhoneNumber: string = `${environment.providerPhoneNumber}`;
            this.message ='This email address is already registered with ' + this.registerUser.lastName + ',  '+ this.registerUser.firstName +' and ' + this.registerUser.email + '.  The application associated with this email address has been submitted. Please allow 7 to 10 business days from the time of submission to check the status of the application. If you have any question in regards to enrollment process, please call our National Provider Service Line at ' + providerPhoneNumber;
          }
          else if (response.reactivate === 'Y') {
            this.buttonText = 'Send New Link';
            this.flagType = 'reactivate';
            this.message = 'This email address is already registered with ' + this.registerUser.lastName + ',  '+ this.registerUser.firstName +' . The link in your email has expired. To continue, you can request a new link to ' + this.registerUser.email;

          }
          else if (response.resend_Activation === 'Y') {
            this.buttonText = 'Send New Link';
            this.flagType = 'resendActivation';
            this.success = false;
            this.error = true;
            this.message = 'This email is already registered with ' + this.registerUser.lastName + ',  '+ this.registerUser.firstName +' and ' + this.registerUser.email + '. Please check your email for the link to begin your enrollment form. You can also request a new link.';


          }
          else if (response.resume_App === 'Y') {
            this.buttonText = 'Send New Link';
            this.message = 'This email is already registered with ' + this.registerUser.lastName + ',  '+ this.registerUser.firstName +' and ' + this.registerUser.email + ' and verified. Please check your email for the link to begin your enrollment form. You can also request a new link.';


          }
          else if (response.start_App === 'Y') {
            this.buttonText = 'Send New Link';
            this.message = 'This email is already registered with ' + this.registerUser.lastName + ',  '+ this.registerUser.firstName +' and ' + this.registerUser.email + ' and verified. Please check your email for the link to begin your enrollment form. You can also request a new link.';

          }


          this.registered = true;
          this.registerUserResponse.email_Sent = response.email_Sent;
          this.registerUserResponse.register_Key = response.register_Key;
          this.registerUserResponse.app_Submitted = response.app_Submitted;
          this.registerUserResponse.reactivate = response.reactivate;
          this.registerUserResponse.resend_Activation = response.resend_Activation;
          this.registerUserResponse.resume_App = response.resume_App;
          this.registerUserResponse.start_App = response.start_App;
          console.log(`User registration successfully saved.`);
          this.loading = false;

          this.toastService.show({
            text: 'User registration successfully saved.',
            type: 'success'
          });

        }
        else {
          this.message = `Error occurred creating the registration.`;
          this.success = false;
          this.error = true;
          console.log(`Error occured from service while create user registration.`);
          this.loading = false;

          this.toastService.show({
            text: 'Error occurred creating the registration.',
            type: 'warning'
          });


        }
      },
      error => {
        this.message = `Error occurred creating the registration.`;
        this.success = false;
        this.error = true;
        console.log(`Error occured from service while validate user`);
        this.toastService.show({
          text: 'Error occurred creating the registration.',
          type: 'warning'
        });
      }
    );
  }

  sentNewLink() {
    this.loading = true;
    this.UserService.sentNewLink(this.register_Key, this.flagType).subscribe(

      response => {
        if (response) {
          this.applicationsubmitted = false;
          this.message = 'An email with a link to access your enrollment form has been sent to your email address  ' + this.registerUser.email + ' and ' + this.registerUser.lastName + ',  '+ this.registerUser.firstName +'. Please check your email. '
          console.log(`Sent new link successfully.`);
          this.loading = false;
          this.toastService.show({
            text: 'Sent new link successfully.',
            type: 'success'
          });

        }
        else {
          this.success = false;
          this.error = true;
          console.log(`Error occured while sent new link.`);
          this.loading = false;

          this.toastService.show({
            text: 'Error occured while sent new link.',
            type: 'warning'
          });
        }

      },
      error => {
        this.message = `Error occured while sending email to user: ${error.error}`;
        this.success = false;
        this.error = true;
        this.applicationsubmitted = false;
        console.log(`Error occured while sending email to user`);
        this.toastService.show({
          text: 'Error occured while sending email to user',
          type: 'warning'
        });
      }
    );
  }



  onReset() {
    this.submitted = false;
    this.UserForm.reset();
    this.success = false;
    this.error = false;
    this.buttonText = 'Register';
    this.registered = false;
    this.applicationsubmitted = true;
  }


  public dismiss() {
    this.activeModal.dismiss();
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value.toLowerCase() !== matchingControl.value.toLowerCase()) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

}


