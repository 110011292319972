import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';

import { faCheckSquare, faSpinner, faSquare, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { Popover } from '../shared/popover/popover.service';

import { DataService } from '../services/data.service';
import { UserService } from '../user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-submitted',
  templateUrl: './submitted.component.html',
  styleUrls: ['./submitted.component.scss']
})
export class SubmittedComponent implements OnInit {


  faCheckSquare = faCheckSquare;
  faSpinner = faSpinner;
  faSquare = faSquare;
  faTimesCircle = faTimesCircle;

  register_Key: any;
  npsPhone = '';
  downloadURL = ''; // pdf download url
  statusData: any; // statustracker result object
  statusErr: any; // statustracker error
  appDate = ''; // date/time submitted
  denied: boolean; // denied
  deniedClass = ''; // css class for denied apps
  statusClass = 'success'; // bootstrap color class suffix based on denied or not
  statusDescription = ''; // description of status to be used when currentStatus = true
  currentStatus: number; // id of current status (same as progressStatus)
  progressStatus: number; // id of status currently in progress
  completeStatus: number; // id of last status completed
  statusCount: number; // total number of statuses
  statusPercentage: number; // % allocation for each status
  statusPercentComplete: number; // % complete

  path: string;

  metadata$: Observable<any>;
  enroll$: Observable<any>;

  metadata: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private userService: UserService,
  ) {
    
   }

  ngOnInit() {

    this.register_Key = this.route.snapshot.paramMap.get('register_Key');
    if (this.register_Key === null)
      return;
    console.log("register_Key: "+this.register_Key);
    
    this.downloadURL = this.dataService.getDownloadReviewDocumentUrl(this.register_Key);
    console.log("downloadURL: "+this.downloadURL);

    //this.getStatusData(this.register_Key);
    
    this.dataService.getAppStatus(this.register_Key).subscribe((data) => {
      
      //assign data object to statusData
      console.log("data: ",data);
      console.log("data result: ",data['result']);
      this.statusData = data['result'];
      //console.log("typeof", typeof this.statusData);
      
      //count status items in object
      this.statusCount = Object.keys(this.statusData).length;

      //iterate through statusData
      for (let item of this.statusData){
        
        //set app submitted date
        if(item['order'] === 1){
          this.appDate = item['statusDate'];
        }

        //set denied status
        if(item['isDenied'] === true){
          this.denied = true;
          this.deniedClass = 'denied';
          this.statusClass = 'danger';
        }
        
        
        if(item['isCurrentStatus'] === true){
          //set current status id and progress status id
          this.currentStatus = item['order'];
          this.progressStatus = this.currentStatus;
          this.completeStatus = this.currentStatus-1;
          //set status description
          this.statusDescription = item['description'];
        }
        
        //set isInProgress value
        if(item['order'] === (this.progressStatus)){
          item['isInProgress'] = true;
        } else {
          item['isInProgress'] = false;
        }

      }

      //iterate through statusData again to set progressClass values
      for (let item of this.statusData){
        
        //set class values for progress blocks
        if(this.denied === true){
          if(item['isDenied'] === true){
            item['progressClass'] = "progress-bar bg-danger";
          } else {
            item['progressClass'] = "progress-bar progress-bar-striped progress-bar-animated progress-bar-muted";
          } 
        } else {
          if(item['isInProgress'] === true && item['isComplete'] !== true){
            item['progressClass'] = "progress-bar progress-bar-striped progress-bar-animated bg-success";
          } else if(item['isComplete'] === true){
            item['progressClass'] = "progress-bar bg-primary";
          } else {
            item['progressClass'] = "progress-bar progress-bar-striped progress-bar-animated progress-bar-muted";
          }
        }
      
      }

      //set percent of total width for each status block
      this.statusPercentage = 100/this.statusCount;

      //set percent of total process completed
      this.statusPercentComplete = this.completeStatus / this.statusCount;
      
    },
    (err) => {
      console.error("Error Message: ",err.message);
      console.error("Error Details: ",err);
      this.statusErr = err.message;
    });
    
    //set npsphone from environment variables
    this.npsPhone = `${environment.providerPhoneNumber}`;

  }

}