import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SampleComponent } from './sample/sample.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { UserComponent } from './user/user.component';
import { ValidateuserComponent } from './validateuser/validateuser.component';
import { SubmittedComponent } from './submitted/submitted.component';
import { HealthCheckGuard } from './services/healthcheck.guard';
import { DownComponent } from './down/down.component';
import { HealthPageComponent } from './healthpage/healthpage.component';

import { CanceledGuard } from './services/canceled.guard';
import { InvalidComponent } from './invalid/invalid.component';
import { NotFoundGuard } from './services/notfound.guard';

const routes: Routes = [
  {path: '', redirectTo: '/user', pathMatch: 'full'},
  {path: 'join', loadChildren: () => import('./join/join.module').then(m=> m.JoinModule), canActivate: [
    HealthCheckGuard,
    NotFoundGuard,
    CanceledGuard
  ]},
  {path: 'internal/join', loadChildren: () => import('./join/join.module').then(m=> m.JoinModule), canActivate: [
    HealthCheckGuard
  ]},
  {path: 'confirm', component: ConfirmComponent},
  {path: 'user', component: UserComponent, canActivate: [HealthCheckGuard] },
  {path: 'validate/:register_Key', component: ValidateuserComponent , canActivate: [
    HealthCheckGuard,
    NotFoundGuard,
    CanceledGuard
  ]},
  {path: 'submitted/:register_Key', component: SubmittedComponent },
  {path: 'down', component: DownComponent},
  {path: 'healthpage', component: HealthPageComponent},
  {path: 'invalid/:status', component: InvalidComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
