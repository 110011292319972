
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { RegisterUser, RegisterUserResponse } from './user';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private registerUser = 'user/register';
  private uservalidate = 'user/validate';
  private sendNotification = 'user/sendNotification';
  private sendCancelationEmail = 'user/sendCancelation';
  private userStatusValidate = 'user/validateUserStatus';
  
  
  private user = 'user';

  constructor(private http: HttpClient) { }

  public saveUser(RegisterUser: RegisterUser) {
    const userSaveURL = `${environment.providerAPIEndpointExternal}/${this.registerUser}`;

    return this.http.post(userSaveURL, RegisterUser).pipe(
      tap(content => this.log(`User created  ${content['entityId']}`)),
      catchError(this.handleError<any>(`upload`))
    );

  }

  public sentNewLink(register_Key: string, flagType: string) {
    const uservalidateURL = `${environment.providerAPIEndpointExternal}/${this.user}/${flagType}`;
    return this.http.post(`${uservalidateURL}/${register_Key}`, '').pipe(
      tap(content => this.log(`Send new link ${content['register_Key']}`)),
      catchError(this.handleError<any>(`Send new link failed`))
    );

  }

   public validateUserStatus(register_Key: string) {
    const uservalidateURL = `${environment.providerAPIEndpointExternal}/${this.userStatusValidate}`;
    return this.http.post(`${uservalidateURL}/${register_Key}`, '').pipe(
      tap(content => this.log(`Validate user ${content['register_Key']}`)),
      catchError(this.handleError<any>(`Validate user failed`))
    );
  }

  public validateUser(register_Key: string) {
    const uservalidateURL = `${environment.providerAPIEndpointExternal}/${this.uservalidate}`;
    return this.http.post(`${uservalidateURL}/${register_Key}`, '').pipe(
      tap(content => this.log(`Validate user ${content['register_Key']}`)),
      catchError(this.handleError<any>(`Validate user failed`))
    );
  }

  
  public userInActivity(register_Key: string) {
    const uservalidateURL = `${environment.providerAPIEndpointExternal}/${this.sendCancelationEmail}`;
    return this.http.post(`${uservalidateURL}/${register_Key}`, '').pipe(
      tap(content => this.log(`Camceled user ${content['register_Key']}`)),
      catchError(this.handleError<any>(`Camceled user failed`))
    );
  }

  
  public sentActivateEmail(register_Key: string) {
    const uservalidateURL = `${environment.providerAPIEndpointExternal}/${this.sendNotification}`;
    return this.http.post(`${uservalidateURL}/${register_Key}`, '').pipe(
      tap(content => this.log(`Send new link ${content['register_Key']}`)),
      catchError(this.handleError<any>(`Send new link failed`))
    );

  }


  private handleError<T>(operation = 'user notification', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}

