// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  providerAPIEndpointExternal: '/api/external',
  // providerAPIEndpointExternal: 'https://joindev.beaconhealthoptions.com/api/external',

  providerAPIEndpointInternal: '/api/internal',
  providerPhoneNumber: '800-397-1630 (Monday through Friday, 8 a.m. to 8 p.m. EST).',
  providerJoinForm: {
    version: '2.0'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
