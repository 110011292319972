import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { UserService } from '../user/user.service';
import { RegisterUser, RegisterUserResponse } from '../user/user';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastService } from '../services/toast/toast.service';
import { Component, OnInit, Input, ElementRef, ViewChild ,VERSION} from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  title = 'User Notificaton';
  @Input() actionType;
  @Input() RegisterUserResponse: RegisterUserResponse[];

  error: boolean = false;
  message = 'Are you sure you want to cancel your application?';
  buttonText = 'Yes';
  success: boolean = false;
  applicationCancel: boolean = true;
  applicationActivate: boolean = false;
  submitted: boolean = true;
  register_Key: any;
  registerUser = new RegisterUser;
  registerUserResponse = new RegisterUserResponse;
  loading = false;
  email = '';
  lastName = '';
  firstName = '';
  status  = '';
  errMessage  = '';

  constructor(
    private UserService: UserService,
    public activeModal: NgbActiveModal,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private locationStrategy: LocationStrategy
    ) { }

    onSubmit(event: any) {
      if (this.register_Key === null)
      return;
      this.buttonText=event.toElement.name;
      this.cancelApplication(this.register_Key);
    }
  
    ngOnInit() {
      this.register_Key = this.route.snapshot.paramMap.get('register_Key');
      if (this.register_Key === null)
        return;
       this.loading = true;
       debugger;
       this.ValidateUserStatus(this.register_Key);
       this.loading = false;
    }
      
    cancelApplication(register_Key: string) 
    {
      this.loading = true;
      if (this.buttonText === 'CancelUser') 
      {
        this.updateNotification(register_Key);
        this.loading = false;
       
      }
      else {
        this.sentNotification(register_Key);
        this.loading = false;
        this.preventBackButton();
      }
    }

    updateNotification(register_Key: string) {
      this.UserService.userInActivity(this.register_Key).subscribe(
          response => {
          if (response) {
            this.applicationCancel = false;
            this.applicationActivate =true;
            this.submitted=true;
            this.message = "Your application has been successfully canceled. "
            console.log(`Cancel your application successfully.`);
              this.toastService.show({
              text: 'Cancel your application successfully.',
              type: 'success'
            });
          }
          else {
            this.success = false;
            this.error = true;
            console.log(`Error occured while cancel your application.`);
              this.toastService.show({
              text: 'Error occured while cancel your application.',
              type: 'warning'
            });
          }
        },
        error => {
          this.message = `Error occured cancel your application: ${error.error}`;
          this.success = false;
          this.error = true;
          this.applicationCancel = false;
          console.log(`Error occured while cancel your application`);
          this.toastService.show({
            text: 'Error occured while cancel your application',
            type: 'warning'
          });
        }
      );
    }

    sentNotification(register_Key: string) {
      this.UserService.sentActivateEmail(this.register_Key).subscribe(
          response => {
          if (response) {
            this.applicationCancel = true;
            this.applicationActivate =false;
            this.submitted=true;
            this.message = "Your enrollment application is still active. Please check your email for a new message containing a link to complete your application. "
            console.log(`send notification successfully.`);
              this.toastService.show({
              text: 'send notification successfully.',
              type: 'success'
            });
          }
          else {
            this.success = false;
            this.error = true;
            console.log(`Error occured while send notification.`);
              this.toastService.show({
              text: 'Error occured while send notification.',
              type: 'warning'
            });
          }
        },
        error => {
          this.message = `Error occured while send notification: ${error.error}`;
          this.success = false;
          this.error = true;
          this.applicationCancel = false;
          console.log(`Error occured while send notification.`);
          this.toastService.show({
            text: 'Error occured while send notification.',
            type: 'warning'
          });
        }
      );
    }

    ValidateUserStatus(register_Key: string) {

      this.UserService.validateUserStatus(register_Key).subscribe(
        response => {
  
          if (response) {

            if(response.errMessage==='INVALID REGISTER KEY' || response.status !=='W' )
            {
              this.applicationCancel = true;
              this.applicationActivate =false;
              this.submitted=true;
              this.message = "Your request to join the Carelon Behavioral Health network has been cancelled. if you would like to reapply, please click the link below and follow the instructions on how to become a provider. https://www.carelonbehavioralhealth.com/providers/how-to-become-a-provider/"
            }
            else
            {
              this.errMessage=response.errMessage;
              this.status = response.status;
              this.submitted=false;
              this.message = "Are you sure you want to cancel your application?"
              console.log(`User status successfully validated.`);
                this.toastService.show({
                text: 'User status successfully validated.',
                type: 'success'
              }); 
            }                        
  
          }
          else {
            this.message = `Error occurred validate user current status.`;
            this.success = false;
            this.error = true;
            console.log(`Error occured from service while validate user current status.`);
            this.toastService.show({
            text: 'Error occurred validate user current status.',
            type: 'warning'
          });
  
          }
        },
        error => {
          this.message = `Error occurred validate user current status.`;
          this.success = false;
          this.error = true;
          console.log(`Error occured from service while validate user current status.`);
          this.toastService.show({
            text: 'Error occurred validate user current status.',
            type: 'warning'
          });
        }
      );
    }
  

  // Define a function to handle back button and use anywhere
  preventBackButton() {
      history.pushState(null, null, location.href);
      this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }

}
