import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, FormGroup, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings ,RecaptchaFormsModule } from 'ng-recaptcha';

import { SharedModule } from './shared/shared.module';
import { PopoverComponent } from './shared/popover/popover.component';

import { ToastModule } from './services/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SampleComponent } from './sample/sample.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { UserComponent } from './user/user.component';
import { DownComponent } from './down/down.component';

import { ValidateuserComponent } from './validateuser/validateuser.component';
import { SubmittedComponent } from './submitted/submitted.component';
import { HealthPageComponent } from './healthpage/healthpage.component';
import { InvalidComponent } from './invalid/invalid.component';
import { NotificationComponent } from './notification/notification.component';


@NgModule({
  declarations: [
    AppComponent,
    SampleComponent,
    ConfirmComponent,
    UserComponent,
    ValidateuserComponent,
    PopoverComponent,
    SubmittedComponent,
    DownComponent,
    HealthPageComponent,
    InvalidComponent,
    NotificationComponent
   ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    ToastModule.forRoot(),
    TooltipModule.forRoot(),
    FormsModule, 
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  bootstrap: [AppComponent],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6Lfmj8EUAAAAAADrcK1LL6gR9-Ko8Q1DkuzUROGv',
    } as RecaptchaSettings,
  },NgbActiveModal],
  entryComponents: [PopoverComponent],
})
export class AppModule { }
