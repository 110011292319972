export class RegisterUser 
{
    email: string;
    confirmEmail: string;
    firstName: string;
    lastName: string;
    register_Key: string;
      
}



export class RegisterUserResponse 
{
    email_Sent: string;
    resend_Activation: string;
    reactivate: string;
    start_App: string;
    resume_App: string;
    app_Submitted: string;
    register_Key: string;
      
}


export class ValidateUserResponse 
{
     registerKey: string;
      
}










